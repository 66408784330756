import React from 'react';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, setSubmittedStatus, getSubmittedStatus } from '../firebase';
// import {
//   y9Options2022,
//   tutorGroups,
//   pathways,
//   eligibleToChooseCS,
// } from '../lib/data';
import {
  y9Options2022,
  tutorGroups,
  pathways,
} from '../lib/data';
import SubjectSelect from './SubjectSelect';
import { validate } from '../lib/validationRules';
import ValidationBar from './ValidationBar';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

NProgress.configure({
  template:
    '<div style="height: 100%; opacity:0.4" " class="bar" role="bar"><div ></div></div></div>',
});

const OptionsForm = () => {
  // initial state of the form fields that are not select menus
  const initialState = {
    'First Name': '',
    'Last Name': '',
    selectedSubjects: [],
  };

  const [user, loading] = useAuthState(auth);
  //const [name, setName] = useState('');
  // router to change pages if not logged in
  const navigation = useNavigate();

  // const fetchUserName = async () => {
  //   try {
  //     const query = await db
  //       .collection('users')
  //       .where('uid', '==', user?.uid)
  //       .get();
  //     const data = await query.docs[0].data();
  //     setName(data.name);
  //     console.log(data);
  //   } catch (err) {
  //     console.error(err);
  //     //alert('An error occured while fetching user data');
  //   }
  // };

  // set up hooks for the state of the form
  const [form, setForm] = useState(initialState);
  const [validationErrorMessages, setValidationErrorMessages] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [canChooseCS, setCanChooseCS] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  // number of allowed subject selections
  const numberOfSubjectChoicesAllowed = 5;

  useEffect(() => {
    const fetchSubmittedStatus = async (user) => {
      const submittedStatus = await getSubmittedStatus(user);
      setSubmitted(submittedStatus);
    };
    console.log(loading);
    // if loading do nothing
    if (loading) return;
    // if not logged in return to login screen
    if (!user) return navigation('/');
    // check if they've already submitted a form
    fetchSubmittedStatus(user);
    if (submitted) {
      // they have, so redirect
      navigation('/submitted');
    }
    // check if they're logged in with a range email
    const domain = user.email.split('@')[1].toLowerCase();
    if (domain !== 'range.sefton.sch.uk') {
      console.log(domain);
      console.log('not logged in with range account');
      setValidationErrorMessages([
        `You're logged in as ${user.email}.  This is not a valid Range High School account.  Please use the sign out button from the top-right menu`,
      ]);
    }

    // console.log(auth.currentUser.displayName);

    // must be logged in so pull the fn and sn from auth and
    // set as values on the form
    setForm((prev) => ({
      ...prev,
      'First Name': auth.currentUser.displayName.split(' ')[0],
      'Last Name': auth.currentUser.displayName.split(' ')[1],
    }));

    // check if the logged in person is eligible to choose CS
    setCanChooseCS(isEligibleToChooseCS(auth.currentUser.email));
  }, [user, loading, navigation, submitted]);

  // useEffect(() => {
  //   const fetchSubmittedStatus = async (user) => {
  //     const submittedStatus = await getSubmittedStatus(user);
  //     setSubmitted(submittedStatus);
  //   };
  //   // if loading do nothing
  //   if (loading) return;
  //   // if not logged in return to login screen
  //   if (!user) return navigation('/');

  //   fetchSubmittedStatus(user);

  //   if (submitted) {
  //     navigation('/submitted');
  //   }
  // }, [user, loading, navigation, submitted]);

  const isEligibleToChooseCS = (email) => {
    //return eligibleToChooseCS.includes(email);
    return true;
    
  };

  // handle changes on the form inputs
  const handleChange = ({ target }) => {
    const { name, value } = target;

    setForm((prevForm) => {
      switch (name) {
        // if the user is changing the subjectSelect drop-down,
        // we need to append the new value to the existing array
        case 'subjectSelect':
          return {
            ...prevForm,
            selectedSubjects: [...prevForm.selectedSubjects, value],
            // set the drop-down back to default
            subjectSelect: 'select-option',
          };
        // if the user changes the science pathway to separate,
        // Physics needs to be set as the first option no matter what
        case 'pathway-Science':
          if (
            value ===
            '3 Separate Sciences (Please note entry requirements for Separate Sciences)'
          ) {
            return {
              ...prevForm,
              [name]: value,
              selectedSubjects: ['Separate Sciences'],
            };
          } else {
            // not selected separate science.  Reset any selected subjects
            return {
              ...prevForm,
              [name]: value,
              selectedSubjects: [],
            };
          }
        // handle all other inputs not listed above
        default:
          return {
            ...prevForm,
            [name]: value,
          };
      }
    });
  };

  const handleSubjectSelectReset = (e) => {
    // prevent page reload
    e.preventDefault();

    setForm((prevForm) => ({
      ...prevForm,
      subjectSelect: 'select-option',
      'pathway-Science': 'select-option',
      selectedSubjects: [],
    }));
  };

  const handleSubmit = async (e) => {
    // stop the page refreshing
    e.preventDefault();
    // move up the page to see any error messages
    window.scrollTo(0, 0);

    // clear validation messages
    setValidationErrorMessages([]);

    // get any validation errors from the form
    const returnedValidationErrorsArray = await validate(form);

    // check they're logged on with a range email
    const domain = user.email.split('@')[1].toLowerCase();
    if (domain !== 'range.sefton.sch.uk') {
      returnedValidationErrorsArray.unshift(
        `You're logged in as ${user.email}.  This is not a valid Range High School account.  Please use the sign out button from the top-right menu`
      );
    }
    // check if there are any validation errors
    if (returnedValidationErrorsArray.length > 0) {
      // set validation error to state
      setValidationErrorMessages(returnedValidationErrorsArray);
      // early return
      return;
    }
    // url to the google sheet api
    const url = process.env.REACT_APP_GOOGLE_SCRIPT_URL;

    // set the FormData up from state to be able to be posted
    // to google sheets
    const data = new FormData();
    data.set('First Name', form['First Name']);
    data.set('Last Name', form['Last Name']);
    data.set('Tutor', form['tutor']);
    data.set('Science Pathway', form['pathway-Science']);
    data.set('Email', user.email);

    // set up the options selected on the form as FormData
    form.selectedSubjects.forEach((subject, index) => {
      data.set(`Option ${index + 1}`, subject);
    });

    // prevent users from trying to submit twice
    setSubmitting(true);
    NProgress.start();

    try {
      // submit to google sheets
      const response = await fetch(url, {
        method: 'POST',
        body: data,
      });
      if (response.ok) {
        //const jsonResponse = await response.json();
        // reset form

        // log to the db that they've submitted and also the options chosen
        try {
          await setSubmittedStatus(user, form.selectedSubjects);
        } catch (e) {
          console.log(e);
        }

        setForm((prevForm) => ({
          ...prevForm,
          ...initialState,
          subjectSelect: 'select-option',
          tutor: 'select-option',
          'pathway-Science': 'select-option',
          selectedSubjects: [],
        }));

        // reset validation messages
        setValidationErrorMessages(['Your options have been submitted']);

        // redirect
        navigation('/submitted');

        //console.log(jsonResponse);
      }
    } catch (error) {
      setValidationErrorMessages([
        `There was an error submitting the form: ${error}`,
      ]);
      console.log(error);
    }
    NProgress.done();
    setSubmitting(false);
  };

  return (
    // <>
    //   <ValidationBar messages={validationErrorMessages} />

    //   <ol>
    //     {form.selectedSubjects.map((subject) => {
    //       return <li key={subject}>{subject}</li>;
    //     })}
    //   </ol>
    //   <form onSubmit={handleSubmit}>
    //     <label htmlFor='First Name'>First Name</label>
    //     <input
    //       type='text'
    //       name='First Name'
    //       value={form['First Name']}
    //       onChange={handleChange}
    //     />
    //     <label htmlFor='Last Name'>Last Name</label>
    //     <input
    //       type='text'
    //       name='Last Name'
    //       value={form['Last Name']}
    //       onChange={handleChange}
    //     />
    //     <label htmlFor='tutor'>Tutor</label>
    //     <select
    //       name='tutor'
    //       onChange={handleChange}
    //       value={form['tutor']}
    //       defaultValue='select-option'
    //     >
    //       <option disabled value='select-option'>
    //         -- select an option --
    //       </option>
    //       {tutorGroups.map((tutorGroup) => {
    //         return (
    //           <option key={tutorGroup} value={tutorGroup}>
    //             {tutorGroup}
    //           </option>
    //         );
    //       })}
    //     </select>
    //     {Object.keys(pathways).map((pathway) => {
    //       return (
    //         <div key={`pathway-${pathway}`}>
    //           <label htmlFor={'pathway' + pathway}>{pathway} pathway</label>
    //           <select
    //             name={`pathway-${pathway}`}
    //             onChange={handleChange}
    //             value={form[`pathway-${pathway}`]}
    //             defaultValue='select-option'
    //           >
    //             <option disabled value='select-option'>
    //               -- select an option --
    //             </option>
    //             {pathways[pathway].map((option) => {
    //               return (
    //                 <option key={option} value={option}>
    //                   {option}
    //                 </option>
    //               );
    //             })}
    //           </select>
    //         </div>
    //       );
    //     })}

    //     <SubjectSelect
    //       disabled={
    //         form.selectedSubjects.length >= numberOfSubjectChoicesAllowed
    //       }
    //       value={form['subjectSelect']}
    //       //selected={form}
    //       inputName='subjectSelect'
    //       inputLabel={
    //         form.selectedSubjects.length >= numberOfSubjectChoicesAllowed
    //           ? 'Limit reached'
    //           : `Choice number ${form.selectedSubjects.length + 1}`
    //       }
    //       subjects={y9Options2022}
    //       alreadySelected={form.selectedSubjects}
    //       onChange={handleChange}
    //     />

    //     <button onClick={handleSubjectSelectReset}>
    //       Reset Subject Choices
    //     </button>
    //     <button disabled={submitting} type='submit'>
    //       Submit
    //     </button>
    //   </form>
    // </>

    // -----------------------------------------------------------------

    <>
      {submitting && <h1>Please wait</h1>}
      <form onSubmit={handleSubmit}>
        <ValidationBar messages={validationErrorMessages} />
        <div className='hidden sm:block' aria-hidden='true'>
          <div className='py-5'>
            <div className='border-t border-gray-200' />
          </div>
        </div>

        <div className='mt-10 sm:mt-0'>
          <div className='md:grid md:grid-cols-3 md:gap-6'>
            <div className='md:col-span-1'>
              <div className='px-4 sm:px-0'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  About You
                </h3>
                <p className='mt-1 text-sm text-gray-600'>
                  Please provide your name and tutor group
                </p>
              </div>
            </div>
            <div className='mt-5 md:mt-0 md:col-span-2'>
              <div className='shadow overflow-hidden sm:rounded-md'>
                <div className='px-4 py-5 bg-white sm:p-6'>
                  <div className='grid grid-cols-6 gap-6'>
                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='first-name'
                        className='block text-sm font-medium text-gray-700'
                      >
                        First name
                      </label>
                      <input
                        type='text'
                        name='First Name'
                        id='first-name'
                        value={form['First Name']}
                        autoComplete='given-name'
                        onChange={handleChange}
                        className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='last-name'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Last name
                      </label>
                      <input
                        type='text'
                        name='Last Name'
                        id='last-name'
                        value={form['Last Name']}
                        autoComplete='family-name'
                        onChange={handleChange}
                        className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                      />
                    </div>
                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='tutor'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Tutor
                      </label>
                      <select
                        name='tutor'
                        onChange={handleChange}
                        value={form['tutor']}
                        defaultValue='select-option'
                        className='w-80'
                      >
                        <option disabled value='select-option'>
                          -- select an option --
                        </option>
                        {tutorGroups.map((tutorGroup) => {
                          return (
                            <option key={tutorGroup} value={tutorGroup}>
                              {tutorGroup}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='hidden sm:block' aria-hidden='true'>
          <div className='py-5'>
            <div className='border-t border-gray-200' />
          </div>
        </div>

        <div className='mt-10 sm:mt-0'>
          <div className='md:grid md:grid-cols-3 md:gap-6'>
            <div className='md:col-span-1'>
              <div className='px-4 sm:px-0'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  Science Pathway
                </h3>
                <p className='mt-1 text-sm text-gray-600'>
                  Choose a science pathway
                </p>
                <p className='mt-1 text-sm text-gray-600'>
                </p>
              </div>
            </div>
            <div className='mt-5 md:mt-0 md:col-span-2'>
              <div className='shadow overflow-hidden sm:rounded-md'>
                <div className='px-4 py-5 bg-white sm:p-6'>
                  <div className='grid grid-cols-6 gap-6'>
                    <div className='col-span-6 sm:col-span-3'>
                      {Object.keys(pathways).map((pathway) => {
                        return (
                          <div key={`pathway-${pathway}`}>
                            <label
                              className='block text-sm font-medium text-gray-700'
                              htmlFor={'pathway' + pathway}
                            >
                              {pathway} pathway
                            </label>
                            <select
                              name={`pathway-${pathway}`}
                              onChange={handleChange}
                              value={form[`pathway-${pathway}`]}
                              defaultValue='select-option'
                              className='w-80'
                            >
                              <option disabled value='select-option'>
                                -- select an option --
                              </option>
                              {pathways[pathway].map((option) => {
                                return (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='hidden sm:block' aria-hidden='true'>
          <div className='py-5'>
            <div className='border-t border-gray-200' />
          </div>
        </div>

        <div className='mt-10 sm:mt-0'>
          <div className='md:grid md:grid-cols-3 md:gap-6'>
            <div className='md:col-span-1'>
              <div className='px-4 sm:px-0'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  Options
                </h3>
                <p className='mt-2 text-sm text-gray-600'>
                  Please select your 5 option choices in order of preference
                </p>
                <p className='mt-2 text-sm text-gray-600'>
                You will be allocated a place on three option choices, and if you're
                allocated a reserve option choice, the school will be in touch.
                </p>

                {!canChooseCS && (
                  <p className='mt-2 text-sm text-gray-600'>
                    If you are unable to select Computer Science please see Mr
                    Cadwell in relation to the entry requirements for this
                    subject
                  </p>
                )}
              </div>
            </div>
            <div className='mt-5 md:mt-0 md:col-span-2'>
              <ol className='list-decimal bg-slate-100 p-8'>
                {form.selectedSubjects.map((subject) => {
                  return (
                    <li className='pt-2 text-xl font-extrabold' key={subject}>
                      {subject}
                    </li>
                  );
                })}
              </ol>
              <div className='shadow overflow-hidden sm:rounded-md'>
                <div className='px-4 py-5 bg-white sm:p-6'>
                  <div className='grid grid-cols-6 gap-6'>
                    <div className='col-span-6 sm:col-span-3'>
                      <SubjectSelect
                        canChooseCS={canChooseCS}
                        disabled={
                          form.selectedSubjects.length >=
                          numberOfSubjectChoicesAllowed
                        }
                        value={form['subjectSelect']}
                        //selected={form}
                        inputName='subjectSelect'
                        inputLabel={
                          form.selectedSubjects.length >=
                          numberOfSubjectChoicesAllowed
                            ? 'Limit reached'
                            : `Choice number ${
                                form.selectedSubjects.length + 1
                              }`
                        }
                        subjects={y9Options2022}
                        alreadySelected={form.selectedSubjects}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='shadow overflow-hidden sm:rounded-md'>
                <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
                  <button
                    className='inline-flex justify-center py-2 px-4 mr-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={handleSubjectSelectReset}
                  >
                    Reset Subject Choices
                  </button>

                  <button
                    className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    disabled={submitting}
                    type='submit'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default OptionsForm;
